<template>
  <div class="container">
    <Header :active="1" />
    <div class="carousel-box">
      <el-carousel
        :autoplay="false"
        :height="carouselHeight"
        indicator-position="outside"
        trigger="click"
        style="width: 100%"
      >
        <el-carousel-item v-for="item in bannerList" :key="item.text1">
          <div class="carousel-item">
            <!-- <el-image
              class="carousel-img"
              style="width: 100%"
              :src="item.img"
              lazy
            ></el-image> -->
            <img
              class="carousel-img"
              ref="carouselImg"
              style="width: 100%"
              :src="item.img"
              alt=""
            />
            <div class="carousel-item-text-box">
              <div class="carousel-item-text-box-title">
                {{ item.text1 }}
              </div>
              <div class="carousel-item-text-box-tips">
                {{ item.text2 }}
              </div>
              <div
                class="horizontal-line"
                :style="{ marginTop: pxToRem(23), marginBottom: pxToRem(40) }"
              ></div>
              <div class="carousel-item-text-box-info">
                {{ item.text3 }}
              </div>
            </div>
          </div>
        </el-carousel-item>
      </el-carousel>
    </div>
    <div class="section1">
      <div class="section1-box">
        <!-- <div class="section1-box-tab">
          <div
            @click="changeTab(0)"
            :style="{ paddingLeft: pxToRem(50) }"
            :class="[
              tabIndex == 0 ? 'section1-box-tab-item-active' : '',
              'section1-box-tab-item',
            ]"
          >
            辅助生活与记忆照护
          </div>
          <div
            @click="changeTab(1)"
            :class="[
              tabIndex == 1 ? 'section1-box-tab-item-active' : '',
              'section1-box-tab-item',
            ]"
          >
            居家社区照护
          </div>
          <div
            @click="changeTab(2)"
            :style="{ paddingLeft: pxToRem(50) }"
            :class="[
              tabIndex == 2 ? 'section1-box-tab-item-active' : '',
              'section1-box-tab-item',
            ]"
          >
            专业技术防护
          </div>
        </div> -->
        <div class="section1-box-text">
          “1CARE最吸引人的地方，除了它能够满足居民、护理人员、管理人员的需求以外，就是它的技术简单。
          1CARE系统是硬件最少、安装最快的系统，无需服务器、天线或外部硬件。”
        </div>
        <div class="section1-box-tip">
          <span>——— 1CARE CEO</span>
          <img src="https://ecare-health.oss-cn-beijing.aliyuncs.com/www/icon/arrow-left.png" alt="" />
        </div>
      </div>
    </div>
    <div class="section2 life">
      <div class="title-box">
        <div class="title">辅助生活与记忆照护</div>
        <div class="subtitle">Assisted living and memory care</div>
      </div>
      <div>
        <div class="nursingStaff">
          <!-- <el-image
            class="bigImg"
            src="https://ecare-health.oss-cn-beijing.aliyuncs.com/www/bringUpCenter/life_img1.png"
            lazy
          ></el-image> -->
          <img
            class="bigImg"
            src="https://ecare-health.oss-cn-beijing.aliyuncs.com/www/bringUpCenter/life_img1.png"
            alt=""
          />
          <div class="txtCont">
            <p class="title">
              <img
                class="icon_left"
                src="https://ecare-health.oss-cn-beijing.aliyuncs.com/www/bringUpCenter/icon_left.png"
                alt=""
              />护理人员
            </p>
            <p class="desc">
              护理老人是一项长期的工作，一位护理人员一般需要照护多位老人，无法观察到可能会导致重大健康问题的日常生活活动的细微变化；1CARE利用高端穿戴设备，感知老人日常生活活动的细微变化，识别可能的记忆问题、慢病、尿路感染、跌倒风险等疾病的细微变化，做出早期预警。
            </p>
          </div>
        </div>
        <div class="relatives">
          <div class="txtCont">
            <p class="title">
              老年人与亲人<img
                class="icon_right"
                src="https://ecare-health.oss-cn-beijing.aliyuncs.com/www/bringUpCenter/icon_right.png"
                alt=""
              />
            </p>
            <p class="desc">
              中国传统养老习惯是居家养老，将老人送进养老机构，是一个非常艰难的决定；担心他们的生活是否会受到细致入微的照护、健康状况是否能够及时获知、是否会享受到更多的社交活动、日常生活是否开心、身体不适或生病时是否能够及时获得医治；1CARE可以为您提供客观、科学、令人放心的生活信息，并提出专业的改善护理建议，让您与家人都放心。
            </p>
          </div>
          <div class="imgCont">
            <el-image
              class="bigImg"
              src="https://ecare-health.oss-cn-beijing.aliyuncs.com/www/bringUpCenter/life_img2.png"
              lazy
            ></el-image>
            <!-- <img class="bigImg" src="https://ecare-health.oss-cn-beijing.aliyuncs.com/www/bringUpCenter/life_img2.png" alt=""> -->
            <!-- <img src="https://ecare-health.oss-cn-beijing.aliyuncs.com/www/bringUpCenter/icon_play.png" alt="" class="iconPlay">
            <p class="playTitle">————  老年人与亲人</p> -->
          </div>
        </div>
      </div>
    </div>
    <div class="section2 care">
      <div class="title-box">
        <div class="title">居家/社区照护</div>
        <div class="subtitle">Home / community health care</div>
      </div>
      <div class="txtCont">
        <img
          class="icon_right"
          src="https://ecare-health.oss-cn-beijing.aliyuncs.com/www/bringUpCenter/icon_right.png"
          alt=""
        />
        <div class="desc">
          <p>
            1CARE为选择居家/社区养老的老年人、家属，以及为居家/社区养老提供服务的机构和护理人员，提供具有可操作性的主动健康、护理建议；
          </p>
          <p>
            1CARE通过多模感知技术收集丰富的数据集来训练计算机神经网络，用以揭示与跌倒、尿路感染、抑郁症、心脑血管疾病或糖尿病风险增加相关的关键信息；1CARE系统中用到的一些相关因素包括：营养不良导致跌倒风险增加、不吃饭、每晚起床次数增加、睡眠时间减少和身体活动水平降低；由入厕频率或持续时间增加、不寻常的如厕模式、夜间入厕模式增加以及活动水平降低，导致尿路感染的可能性增加；由不吃饭频率增加、碎片化睡眠、避免强光和阳光以及身体活动水平降低而导致抑郁的早期迹象；由尿频、多饮、多食、消痩变化预测可能发生糖尿病；
          </p>
        </div>
      </div>
      <div class="swiperCont">
        <div class="swiper1 swiper-container">
          <div class="swiper-wrapper">
            <div
              class="swiper-slide"
              v-for="(item, ind) in swiperList1"
              :key="ind"
              :style="{
                background: 'url(' + item.img + ') no-repeat',
                backgroundSize: 'auto 100%',
              }"
            >
              <div class="swiperBlock"></div>
              <div class="swiperText1">
                <div class="swiperText1-title">
                  <span>{{ item.title }}</span>
                  <div class="swiperText1-title-img"></div>
                </div>
                <div
                  class="swiperText1-tips"
                  v-for="(el, i) in item.descList"
                  :key="i"
                >
                  {{ el }}
                </div>
              </div>
            </div>
          </div>
          <!-- Add Pagination -->
          <!-- <div class="swiper1-pagination swiper-pagination"></div> -->
        </div>
        <div class="swiper1-pagination swiper-pagination"></div>
        <div class="swiper-button-next swiper1-next"></div>
        <div class="swiper-button-prev swiper1-prev"></div>
      </div>
    </div>
    <div class="section2 protect">
      <div class="title-box">
        <div class="title">专业技术防护</div>
        <div class="subtitle">Technical protection</div>
      </div>
      <div class="txtCont">
        <img
          class="icon_right"
          src="https://ecare-health.oss-cn-beijing.aliyuncs.com/www/bringUpCenter/icon_right.png"
          alt=""
        />
        <div class="desc">
          <p>
            由于新冠疫情，在社会距离保持变得更加重要的时代，了解我们的护理人员以及居民的活动轨迹，将使我们能够及时对他们的安全和自由做出及时回应和实时决策，阻止疫情爆发
            ；
          </p>
          <p>
            利用1CARE即时接触者感知解决方案，您可以在秒内识别和隔离所有高风险接触者；
          </p>
        </div>
      </div>
      <div class="swiperCont2">
        <div class="swiperBg">
          <p class="swiperTitle">
            {{ swiperActivedObj.title }}
            <img
              class="iconRight"
              src="https://ecare-health.oss-cn-beijing.aliyuncs.com/www/bringUpCenter/icon_right.png"
              alt=""
            />
          </p>
          <p class="swiperTitle">{{ swiperActivedObj.subTitle }}</p>
          <div class="swiperLine"></div>
          <p class="swiperDesc">{{ swiperActivedObj.text1 }}</p>
          <p class="swiperDesc">{{ swiperActivedObj.text2 }}</p>
        </div>
        <div class="carouselCont swiper-container">
          <div class="swiper-wrapper">
            <div
              class="swiper-slide"
              v-for="(item, ind) in swiperList2"
              :key="ind"
            >
              <img :src="item.img" alt="" />
            </div>
          </div>
        </div>
        <img
          src="https://ecare-health.oss-cn-beijing.aliyuncs.com/www/bringUpCenter/icon_prev.png"
          class="carouselPrev"
          alt=""
        />
        <img
          src="https://ecare-health.oss-cn-beijing.aliyuncs.com/www/bringUpCenter/icon_next.png"
          class="carouselNext"
          alt=""
        />
        <div class="progressCont">
          <el-progress
            :stroke-width="6"
            :percentage="progressObj.percentage"
            :color="'#90c446'"
            :format="format"
          ></el-progress>
          <span>{{ progressObj.page }}/<span class="small">03</span></span>
        </div>
      </div>
    </div>
    <div class="strategic_bg">
      <div class="section2 strategic">
        <div class="title-box">
          <div class="title">全天候的精心呵护</div>
          <div class="subtitle">24/7 Valuable Insigh</div>
        </div>
        <div class="wristbandCont">
          <div class="contLeft">
            <div v-for="(item, ind) in strategicList" :key="ind">
              <div
                v-if="ind < 4"
                :class="item.actived ? 'itemCont active' : 'itemCont'"
              >
                <div class="titleBg" @click="handleShowDetail(ind)">
                  <p class="txt">{{ item.title }}</p>
                  <img
                    class="icon"
                    v-if="item.actived"
                    src="https://ecare-health.oss-cn-beijing.aliyuncs.com/www/bringUpCenter/icon_top_white.png"
                    alt=""
                  />
                  <img
                    class="icon"
                    v-if="!item.actived"
                    src="https://ecare-health.oss-cn-beijing.aliyuncs.com/www/bringUpCenter/icon_top.png"
                    alt=""
                  />
                </div>
                <div class="cont" v-if="item.actived">
                  <ul>
                    <li v-for="(el, i) in item.subTitleList" :key="i">
                      {{ el }}
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <el-image
            class="wristbandImg"
            src="https://ecare-health.oss-cn-beijing.aliyuncs.com/www/bringUpCenter/wristband.png"
            lazy
          ></el-image>
          <!-- <img class="wristbandImg" src="https://ecare-health.oss-cn-beijing.aliyuncs.com/www/bringUpCenter/wristband.png" alt=""> -->
          <div class="contRight">
            <div v-for="(item, ind) in strategicList" :key="ind">
              <div
                v-if="ind >= 4"
                :class="item.actived ? 'itemCont active' : 'itemCont'"
              >
                <div class="titleBg2" @click="handleShowDetail(ind)">
                  <p class="txt">{{ item.title }}</p>
                  <img
                    class="icon"
                    v-if="item.actived"
                    src="https://ecare-health.oss-cn-beijing.aliyuncs.com/www/bringUpCenter/icon_top_white.png"
                    alt=""
                  />
                  <img
                    class="icon"
                    v-if="!item.actived"
                    src="https://ecare-health.oss-cn-beijing.aliyuncs.com/www/bringUpCenter/icon_top.png"
                    alt=""
                  />
                </div>
                <div class="cont" v-if="item.actived">
                  <ul>
                    <li v-for="(el, i) in item.subTitleList" :key="i">
                      {{ el }}
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="section6_bg">
      <div class="section6">
        <div class="section6-header-box">
          <div class="section6-header-box-title">智慧护理，</div>
          <div class="section6-header-box-title">赋予老人更美好的健康生活</div>
          <div class="section6-header-box-tips">
            Intelligent nursing，<br />
            Give the elderly a better and healthy life
          </div>
        </div>
        <div class="section6-box">
          <div class="section6-box-row" v-if="!sectionSixActivedObj">
            <div
              v-for="(item, ind) in sectionSixList"
              :key="item.img"
              class="section6-box-row-item"
            >
              <!-- <img :src="item.img" alt="" /> -->
              <div @click="handleShowDetailSix(ind)">
                <div class="section6-box-row-item-img">
                  <img
                    :src="item.img"
                    :style="{
                      width: pxToRem(item.width),
                      height: pxToRem(item.height),
                    }"
                    alt=""
                  />
                </div>
                <div class="section6-box-row-item-text1">{{ item.text1 }}</div>
                <div class="section6-box-row-item-text2">{{ item.text2 }}</div>
              </div>
            </div>
          </div>
          <div class="sectionItemBig" @click="handleHideDetail" v-else>
            <div class="itemLeft">
              <img :src="sectionSixActivedObj.img" alt="" />
              <p>{{ sectionSixActivedObj.text1 }}</p>
            </div>
            <div class="itemRight">
              <p v-for="(el, ind) in sectionSixActivedObj.textArr" :key="ind">
                {{ el }}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="section7_bg">
      <div class="section7">
        <div class="section7-header-box">
          <div class="section7-header-box-title">
            为运营商提供强大的劳动力管理解决方案
          </div>
          <div class="section7-header-box-tips">
            For operators to provide powerful labor management solutions
          </div>
          <div class="horizontal-line"></div>
        </div>
        <div class="section7-box">
          <div class="section7-box-left">
            <p class="section7-box-left-title">
              提高效率
              <img
                class="section7-box-left-img"
                src="https://ecare-health.oss-cn-beijing.aliyuncs.com/www/bringUpCenter/icon_bottom.png"
                alt=""
              />
            </p>
            <p class="section7-box-left-text">
              1CARE可提供精确的工作效率考核，实现资源合理分配；通过管理端或智能手机应用程序，可了解每个员工在现场时的确切位置，并深入了解他们在当班期间工作模式；分析每一位老人护理时间的细微变化，还可以更好地了解这段时间在几周或几个月内是如何变化的，以此增加或提高老人护理级别；通过手环感知功能，感知邻近手环紧急呼救信号，方便工作人员就近处置；在配备电子门锁机构中，1CARE可根据授权范围实现授权通行，以节约通行时间和安全；
            </p>
            <p class="section7-box-left-title">
              健康预警
              <img
                class="section7-box-left-img"
                src="https://ecare-health.oss-cn-beijing.aliyuncs.com/www/bringUpCenter/icon_bottom.png"
                alt=""
              />
            </p>
            <p class="section7-box-left-text">
              通过生活活动的感知信息收集，1CARE可以精准地分析老人合适的护理等级或护理需求，例如可以根据工作人员与老人相处的时间、呼叫频率，来确定提高护理水平，以此满足老人不断变化的需求；
            </p>
          </div>
          <div class="section7-box-right">
            <el-image
              class="section7-box-right-img"
              src="https://ecare-health.oss-cn-beijing.aliyuncs.com/www/bringUpCenter/section7-computer.png"
              lazy
            ></el-image>
            <!-- <img class="section7-box-right-img" src="https://ecare-health.oss-cn-beijing.aliyuncs.com/www/bringUpCenter/section7-computer.png" alt=""> -->
            <!-- <img class="section7-box-right-start" src="https://ecare-health.oss-cn-beijing.aliyuncs.com/www/bringUpCenter/icon_start.png" alt=""> -->
            <div class="section7-box-right-box">
              <img
                class="section7-box-right-box-img"
                src="https://ecare-health.oss-cn-beijing.aliyuncs.com/www/bringUpCenter/icon_right_white.png"
                alt=""
              />
              <p class="section7-box-right-box-text">
                1CARE提供强大的大数据分析功能，以优化管理和护理人员的工作效率。
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="section8">
      <div class="section8-header-box">
        <div class="section8-header-box-title">
          专为养老机构设计的接触者追踪解决方案
        </div>
        <div class="section8-header-box-tips">
          Contact tracing solutions designed for nursing homes
        </div>
        <div class="horizontal-line"></div>
      </div>
      <div class="section8-box">
        <el-image
          class="section8-box-img"
          src="https://ecare-health.oss-cn-beijing.aliyuncs.com/www/bringUpCenter/section8-img.png"
          lazy
        ></el-image>
        <!-- <img class="section8-box-img" src="https://ecare-health.oss-cn-beijing.aliyuncs.com/www/bringUpCenter/section8-img.png" alt=""> -->
        <div class="section8-box-text">
          <img src="https://ecare-health.oss-cn-beijing.aliyuncs.com/www/bringUpCenter/icon_right.png" alt="" />
          <p>
            在养老机构，一刀切的接触者追踪方法无法实行，健康条件恶劣的孱弱老人，无法单独隔离；脆弱的环境因素要求必须采取快速而有力疾病控制措施，1CARE健康精确接触追踪技术是专门为解决养老机构疫情防控而设计。
          </p>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Swiper from "swiper";
export default {
  name: "bringUpCenter",
  data() {
    return {
      carouselHeight: "",
      bannerList: [
        {
          img: "https://ecare-health.oss-cn-beijing.aliyuncs.com/www/bringUpCenter/banner1.png",
          text1: "辅助生活与记忆照护",
          text2: "Relatives always accompany",
          text3:
            "1CARE为居住在机构的养老人员，提供生活辅助和记忆照护，为机构护理人员提供专业指导意见；",
        },
        {
          img: "https://ecare-health.oss-cn-beijing.aliyuncs.com/www/bringUpCenter/banner2.png",
          text1: "居家/社区照护",
          text2: "Home / community care",
          text3:
            "1CARE为居住在机构的养老人员，提供生活辅助和记忆照护，为机构护理人员提供专业指导意见；",
        },
        {
          img: "https://ecare-health.oss-cn-beijing.aliyuncs.com/www/bringUpCenter/banner3.png",
          text1: "专业技术防护",
          text2: "Technical protection",
          text3:
            "1CARE健康使用即时联系人追踪解决方案，您可以识别和隔离所有高风险人员；",
        },
      ],
      tabIndex: 0,
      strategicList: [
        {
          title: "早期预警",
          subTitleList: [
            "1CARE能够感知可能预示着严重健康问题的细微变化，如心率、睡眠、饮食、体重、运动等的细微变化，在疾病发生前，实现早期预警；",
          ],
          index: 0,
          actived: true,
        },
        {
          title: "精准护理",
          subTitleList: ["利用1CARE客观数据，精准调配护理资源，提高护理质量；"],
          index: 1,
          actived: false,
        },
        {
          title: "信息共享",
          subTitleList: [
            "与家人分享健康信息，让家人了解自己每一天的生活细节；",
          ],
          index: 2,
          actived: false,
        },
        {
          title: "轨迹示踪",
          subTitleList: [
            "位置感知技术，通过提供感染者活动轨迹信息，防止老人相互感染，阻止病毒传播；",
          ],
          index: 3,
          actived: false,
        },
        {
          title: "远程会诊",
          subTitleList: [
            "通过1CARE智慧平台，AI智能医生早期预警，您的专属医疗专家实时在线；",
          ],
          index: 4,
          actived: false,
        },
        {
          title: "环境消杀",
          subTitleList: [
            "提供精准消杀路径，避免养老机构全面停业进行消毒，减少不必要的工作量和人员流动；",
          ],
          index: 5,
          actived: false,
        },
        {
          title: "精准定位",
          subTitleList: [
            "1CARE可对居家养老人员和服务人员进行智能感知，获知上门服务时长和居家养老人员安全护栏设置；",
          ],
          index: 6,
          actived: false,
        },
        {
          title: "防止意外",
          subTitleList: [
            "感知如跌倒、睡眠不足等健康状况，在意外发生之前，增加防范措施；",
          ],
          index: 7,
          actived: false,
        },
      ],
      sectionSixList: [
        {
          img: "https://ecare-health.oss-cn-beijing.aliyuncs.com/www/bringUpCenter/section6_img1.png",
          width: 86,
          height: 80,
          text1: "科学建议",
          text2: "",
          actived: true,
          textArr: [
            "1CARE系统中用到的一些相关因素包括：营养不良导致跌倒风险增加、不吃饭、每晚起床次数增加、睡眠时间减少和身体活动水平降低；",
            "由入厕频率或持续时间增加、不寻常的如厕模式、夜间入厕模式增加以及活动水平降低，导致尿路感染的可能性增加；",
            "由不吃饭的频率增加、碎片化睡眠、避免强光和阳光以及身体活动水平降低而导致抑郁的早期迹象；由尿频、多饮、多食、消痩变化预测可能发生糖尿病；",
            "1CARE基于对多模感知技术和人工智能的深度应用，依托智慧慢病管理平台，建立慢病早期监测、辅助诊断及预警系统，已成为智慧康养行业的先行者；",
          ],
        },
        {
          img: "https://ecare-health.oss-cn-beijing.aliyuncs.com/www/bringUpCenter/section6_img2.png",
          width: 86,
          height: 75,
          text1: "机构管理",
          text2: "",
          actived: false,
          textArr: [
            "1CARE系统中用到的一些相关因素包括：营养不良导致跌倒风险增加、不吃饭、每晚起床次数增加、睡眠时间减少和身体活动水平降低；",
            "由入厕频率或持续时间增加、不寻常的如厕模式、夜间入厕模式增加以及活动水平降低，导致尿路感染的可能性增加；",
            "由不吃饭的频率增加、碎片化睡眠、避免强光和阳光以及身体活动水平降低而导致抑郁的早期迹象；由尿频、多饮、多食、消痩变化预测可能发生糖尿病；",
            "1CARE基于对多模感知技术和人工智能的深度应用，依托智慧慢病管理平台，建立慢病早期监测、辅助诊断及预警系统，已成为智慧康养行业的先行者；",
          ],
        },
        {
          img: "https://ecare-health.oss-cn-beijing.aliyuncs.com/www/bringUpCenter/section6_img3.png",
          width: 92,
          height: 75,
          text1: "护理人员",
          text2: "",
          actived: false,
          textArr: [
            "1CARE系统中用到的一些相关因素包括：营养不良导致跌倒风险增加、不吃饭、每晚起床次数增加、睡眠时间减少和身体活动水平降低；",
            "由入厕频率或持续时间增加、不寻常的如厕模式、夜间入厕模式增加以及活动水平降低，导致尿路感染的可能性增加；",
            "由不吃饭的频率增加、碎片化睡眠、避免强光和阳光以及身体活动水平降低而导致抑郁的早期迹象；由尿频、多饮、多食、消痩变化预测可能发生糖尿病；",
            "1CARE基于对多模感知技术和人工智能的深度应用，依托智慧慢病管理平台，建立慢病早期监测、辅助诊断及预警系统，已成为智慧康养行业的先行者；",
          ],
        },
        {
          img: "https://ecare-health.oss-cn-beijing.aliyuncs.com/www/bringUpCenter/section6_img4.png",
          width: 69,
          height: 75,
          text1: "智能AI",
          text2: "",
          actived: false,
          textArr: [
            "1CARE系统中用到的一些相关因素包括：营养不良导致跌倒风险增加、不吃饭、每晚起床次数增加、睡眠时间减少和身体活动水平降低；",
            "由入厕频率或持续时间增加、不寻常的如厕模式、夜间入厕模式增加以及活动水平降低，导致尿路感染的可能性增加；",
            "由不吃饭的频率增加、碎片化睡眠、避免强光和阳光以及身体活动水平降低而导致抑郁的早期迹象；由尿频、多饮、多食、消痩变化预测可能发生糖尿病；",
            "1CARE基于对多模感知技术和人工智能的深度应用，依托智慧慢病管理平台，建立慢病早期监测、辅助诊断及预警系统，已成为智慧康养行业的先行者；",
          ],
        },
        {
          img: "https://ecare-health.oss-cn-beijing.aliyuncs.com/www/bringUpCenter/section6_img5.png",
          width: 90,
          height: 70,
          text1: "褥疮预防",
          text2: "",
          actived: false,
          textArr: [
            "1CARE系统中用到的一些相关因素包括：营养不良导致跌倒风险增加、不吃饭、每晚起床次数增加、睡眠时间减少和身体活动水平降低；",
            "由入厕频率或持续时间增加、不寻常的如厕模式、夜间入厕模式增加以及活动水平降低，导致尿路感染的可能性增加；",
            "由不吃饭的频率增加、碎片化睡眠、避免强光和阳光以及身体活动水平降低而导致抑郁的早期迹象；由尿频、多饮、多食、消痩变化预测可能发生糖尿病；",
            "1CARE基于对多模感知技术和人工智能的深度应用，依托智慧慢病管理平台，建立慢病早期监测、辅助诊断及预警系统，已成为智慧康养行业的先行者；",
          ],
        },
        {
          img: "https://ecare-health.oss-cn-beijing.aliyuncs.com/www/bringUpCenter/section6_img6.png",
          width: 89,
          height: 85,
          text1: "睡眠监测",
          text2: "",
          actived: false,
          textArr: [
            "1CARE系统中用到的一些相关因素包括：营养不良导致跌倒风险增加、不吃饭、每晚起床次数增加、睡眠时间减少和身体活动水平降低；",
            "由入厕频率或持续时间增加、不寻常的如厕模式、夜间入厕模式增加以及活动水平降低，导致尿路感染的可能性增加；",
            "由不吃饭的频率增加、碎片化睡眠、避免强光和阳光以及身体活动水平降低而导致抑郁的早期迹象；由尿频、多饮、多食、消痩变化预测可能发生糖尿病；",
            "1CARE基于对多模感知技术和人工智能的深度应用，依托智慧慢病管理平台，建立慢病早期监测、辅助诊断及预警系统，已成为智慧康养行业的先行者；",
          ],
        },
        {
          img: "https://ecare-health.oss-cn-beijing.aliyuncs.com/www/bringUpCenter/section6_img7.png",
          width: 85,
          height: 72,
          text1: "呼吸暂停",
          text2: "",
          actived: false,
          textArr: [
            "1CARE系统中用到的一些相关因素包括：营养不良导致跌倒风险增加、不吃饭、每晚起床次数增加、睡眠时间减少和身体活动水平降低；",
            "由入厕频率或持续时间增加、不寻常的如厕模式、夜间入厕模式增加以及活动水平降低，导致尿路感染的可能性增加；",
            "由不吃饭的频率增加、碎片化睡眠、避免强光和阳光以及身体活动水平降低而导致抑郁的早期迹象；由尿频、多饮、多食、消痩变化预测可能发生糖尿病；",
            "1CARE基于对多模感知技术和人工智能的深度应用，依托智慧慢病管理平台，建立慢病早期监测、辅助诊断及预警系统，已成为智慧康养行业的先行者；",
          ],
        },
        {
          img: "https://ecare-health.oss-cn-beijing.aliyuncs.com/www/bringUpCenter/section6_img8.png",
          width: 96,
          height: 85,
          text1: "实时定位",
          text2: "",
          actived: false,
          textArr: [
            "1CARE系统中用到的一些相关因素包括：营养不良导致跌倒风险增加、不吃饭、每晚起床次数增加、睡眠时间减少和身体活动水平降低；",
            "由入厕频率或持续时间增加、不寻常的如厕模式、夜间入厕模式增加以及活动水平降低，导致尿路感染的可能性增加；",
            "由不吃饭的频率增加、碎片化睡眠、避免强光和阳光以及身体活动水平降低而导致抑郁的早期迹象；由尿频、多饮、多食、消痩变化预测可能发生糖尿病；",
            "1CARE基于对多模感知技术和人工智能的深度应用，依托智慧慢病管理平台，建立慢病早期监测、辅助诊断及预警系统，已成为智慧康养行业的先行者；",
          ],
        },
        {
          img: "https://ecare-health.oss-cn-beijing.aliyuncs.com/www/bringUpCenter/section6_img9.png",
          width: 86,
          height: 86,
          text1: "免钥进入",
          text2: "",
          actived: false,
          textArr: [
            "1CARE系统中用到的一些相关因素包括：营养不良导致跌倒风险增加、不吃饭、每晚起床次数增加、睡眠时间减少和身体活动水平降低；",
            "由入厕频率或持续时间增加、不寻常的如厕模式、夜间入厕模式增加以及活动水平降低，导致尿路感染的可能性增加；",
            "由不吃饭的频率增加、碎片化睡眠、避免强光和阳光以及身体活动水平降低而导致抑郁的早期迹象；由尿频、多饮、多食、消痩变化预测可能发生糖尿病；",
            "1CARE基于对多模感知技术和人工智能的深度应用，依托智慧慢病管理平台，建立慢病早期监测、辅助诊断及预警系统，已成为智慧康养行业的先行者；",
          ],
        },
        {
          img: "https://ecare-health.oss-cn-beijing.aliyuncs.com/www/bringUpCenter/section6_img10.png",
          width: 87,
          height: 83,
          text1: "智慧预测",
          text2: "",
          actived: false,
          textArr: [
            "1CARE系统中用到的一些相关因素包括：营养不良导致跌倒风险增加、不吃饭、每晚起床次数增加、睡眠时间减少和身体活动水平降低；",
            "由入厕频率或持续时间增加、不寻常的如厕模式、夜间入厕模式增加以及活动水平降低，导致尿路感染的可能性增加；",
            "由不吃饭的频率增加、碎片化睡眠、避免强光和阳光以及身体活动水平降低而导致抑郁的早期迹象；由尿频、多饮、多食、消痩变化预测可能发生糖尿病；",
            "1CARE基于对多模感知技术和人工智能的深度应用，依托智慧慢病管理平台，建立慢病早期监测、辅助诊断及预警系统，已成为智慧康养行业的先行者；",
          ],
        },
      ],
      swiperList1: [
        {
          img: "https://ecare-health.oss-cn-beijing.aliyuncs.com/www/bringUpCenter/section2-swiper1.png",
          title: "管理中心",
          descList: [
            "养老机构和社区服务中心面临着为老人提供一种吸引人的、独立的生活方式的挑战，护理人员的工作效率和满意度，也是管理人员重视的问题；1CARE可以为您提供客观、专业的建议，以提高员工的工作效率，并帮助了解何时应该提高对每个社区养老人员的护理水平，在老人需要时或出现危险状况之前，及时提供更多的帮助和预警；",
          ],
        },
        {
          img: "https://ecare-health.oss-cn-beijing.aliyuncs.com/www/bringUpCenter/section2-swiper2.png",
          title: "护理人员",
          descList: [
            "护理人员无法预测老人身体健康状况变化以及何时需要帮助，1CARE通过多模融合传感器技术，智能感知每位老人独特的日常生活模式，识别出可能需要提前增加防范事件或提升护理等级；",
            "1CARE可获悉员工与老人相处时间，据此安排护理人员和变更护理等级，为老人准确提供护理需求；",
          ],
        },
        {
          img: "https://ecare-health.oss-cn-beijing.aliyuncs.com/www/bringUpCenter/section2-swiper3.png",
          title: "跌倒监测",
          descList: [
            "AI视频分析预警系统，提供智能图像精准识别和高效处理服务，实现视频监控智能化，助力养老机构降本增效，提升管理能力；",
            "AI集群实时分析各类视频，秒级预警、实时取证、事后追溯；",
            "使用原有摄像头进行视频分析，兼容已有监控系统，与第三方平台无缝接入；",
          ],
        },
        {
          img: "https://ecare-health.oss-cn-beijing.aliyuncs.com/www/bringUpCenter/section2-swiper4.png",
          title: "预防褥疮",
          descList: [
            "智能传感器融合AI算法，结合智能物联网大数据分析技术，为长期卧床老人提供实时预警，及时提醒护理人员为老人翻身、清理创面等，防止褥疮生成；",
          ],
        },
        {
          img: "https://ecare-health.oss-cn-beijing.aliyuncs.com/www/bringUpCenter/section2-swiper5.png",
          title: "睡眠障碍监测",
          descList: [
            "中国近40%，超3亿成年人存在睡眠障碍，每年心源性猝死达54.4万，时间段多发生在午夜至凌晨6点，患病率呈逐年上升趋势；",
            "通过智能传感器融合AI算法，全天候感知睡眠碎片化、起夜次数，跟踪趋势变化，提前预警，防止猝死意外发生；",
          ],
        },
        {
          img: "https://ecare-health.oss-cn-beijing.aliyuncs.com/www/bringUpCenter/section2-swiper6.png",
          title: "呼吸暂停综合征监测",
          descList: [
            "呼吸暂停综合征常会引起脑卒中、高血压、冠心病、2型糖尿病、肥胖、性功能障碍、抑郁等疾病，长时间呼吸暂停可导致猝死等严重后果；",
            "智能传感器融合4D成像技术，无接触、全方位、实时监测呼吸率、心率，及时发现潜在睡眠问题，实时预警；",
          ],
        },
      ],
      sectionSixActivedObj: null,
      swiperList2: [
        {
          img: "https://ecare-health.oss-cn-beijing.aliyuncs.com/www/homeCare/swiper2_img1.png",
          title: "智慧居家照护",
          subTitle: "提供者",
          desc: "Smart Home Care",
          text1:
            "1CARE正站在医养技术研发第一线，正努力地为医养事业寻求有效的解决方案；1CARE面临着老人认知下降变化、健康状态变化、员工工作效率以及与通信交流等储多新技术的严峻挑战；",
          text2:
            "1CARE提供及时的可见性和预测性，能够采取主动行动方案来解决日益增长的护理需求，提高员工的工作效率以及根据护理质量提高收入；",
          isactived: true,
        },
        {
          img: "https://ecare-health.oss-cn-beijing.aliyuncs.com/www/homeCare/swiper2_img2.png",
          title: "护理人员",
          subTitle: "",
          desc: "Smart Home Care2",
          text1:
            "照顾居家老人是一项有益于家庭和社会的工作，但有时这也是令人十分沮丧的工作；对于非全职的家庭护理人员来说，不可能观察到可能会导致严重健康问题的生活活动的细微变化；",
          text2:
            "1CARE能够监测到可能表明认知问题、慢性病、尿路感染、跌倒风险和心理健康风险等进展的细微变化；",
          isactived: false,
        },
        {
          img: "https://ecare-health.oss-cn-beijing.aliyuncs.com/www/homeCare/swiper2_img3.png",
          title: "亲情圈",
          subTitle: "",
          desc: "Smart Home Care3",
          text1:
            "让老人完全融入新的群体，让新朋友成为老人日常生活的一部分，并开心生活，这对决定将老人送入养老院的人来说是压力山大的；亲人们担心是否做出了正确的选择，他们时刻想知道他们所爱的人健康是否受到密切监测、意愿是否都得到了满足、或者是否需要更多帮助；",
          text2:
            "1CARE可为家庭护理服务提供者、家属以及老人分享让人放心的重要信息；",
          isactived: false,
        },
      ],
      swiperActivedObj: {},
      progressObj: {
        percentage: 33,
        page: "01",
      },
    };
  },
  mounted() {
    this.getCarouselHeight();
    window.onresize = () => {
      if (document.body.clientWidth > 1200) {
        this.getCarouselHeight();
      }
    };
    this.swiperInit();
    this.swiperInit1();
  },
  methods: {
    getCarouselHeight() {
      let domWidth = document.body.clientWidth;
      // console.log("domWidth", document.body);
      this.carouselHeight = (domWidth * 1606) / 3840 + "px";
    },
    changeTab(i) {
      this.tabIndex = i;
    },
    swiperInit() {
      new Swiper(".swiper1", {
        //自动轮播，这里直接写毫秒就可以了
        // autoplay: 3000,

        //设置环路
        loop: true,

        // 如果需要分页器
        pagination: ".swiper1-pagination",

        // 如果需要前进后退按钮
        nextButton: ".swiper1-next",
        prevButton: ".swiper1-prev",

        // 如果需要滚动条
        // scrollbar: ".swiper-scrollbar",
        slidesPerView: 3,
        spaceBetween: 30,
        uniqueNavElements: false,
      });
    },
    swiperInit1() {
      new Swiper(".carouselCont", {
        paginationClickable: true,
        slidesPerView: 3,
        spaceBetween: -100,
        loop: true,
        slideToClickedSlide: true,
        // 如果需要前进后退按钮
        nextButton: ".carouselNext",
        prevButton: ".carouselPrev",
        onSlideChangeEnd: (swiper) => {
          if (swiper.activeIndex % 3 == 0) {
            this.swiperActivedObj = this.swiperList2[0];
            this.progressObj = {
              percentage: 33,
              page: "01",
            };
          } else if (swiper.activeIndex % 3 == 1) {
            this.swiperActivedObj = this.swiperList2[1];
            this.progressObj = {
              percentage: 66,
              page: "02",
            };
          } else {
            this.swiperActivedObj = this.swiperList2[2];
            this.progressObj = {
              percentage: 100,
              page: "03",
            };
          }
        },
      });
    },
    handleShowDetail(ind) {
      this.strategicList.forEach((el) => {
        el.actived = false;
      });
      this.strategicList[ind].actived = true;
    },
    handleShowDetailSix(ind) {
      this.sectionSixActivedObj = this.sectionSixList[ind];
    },
    handleHideDetail() {
      this.sectionSixActivedObj = null;
    },
    format() {
      return "";
    },
  },
};
</script>
<style lang="less" scoped>
.container {
  background: #eef1f5;
  padding-bottom: 100px;
}
.horizontal-line {
  width: 73px;
  height: 5px;
  background: #90c446;
}
.carousel-box {
  width: 100%;
  .carousel-item {
    .carousel-img {
      position: absolute;
      width: 100%;
      left: 0;
      right: 0;
      z-index: -1;
    }
    &-text-box {
      position: absolute;

      width: 1200px;
      box-sizing: border-box;
      // padding-left: 30px;
      //   padding-right: 780px;
      // height: 300px;
      // left: 360px;
      top: 185px;
      left: 0;
      right: 0;
      margin: 0 auto;
      &-title {
        font-size: 64px;
        color: #1f3876;
        font-family: "UI-Bold";
        span {
          color: #90c446;
        }
      }
      &-tips {
        font-family: "CN-Light";
        color: #90c446;
        font-size: 31px;
      }
      &-info {
        font-family: "CN-Light";
        line-height: 40px;
        font-size: 21px;
        color: #213763;
        width: 540px;
      }
      &-btn {
        width: 120px;
        height: 30px;
        margin-top: 60px;
        cursor: pointer;
        transition: all 0.8s;
      }
      &-btn:hover {
        transform: scale(1.02);
      }
    }
  }
  /deep/ .el-carousel__indicators--outside {
    display: none;
    // position: absolute;
    // bottom: 80px;
    // right: -185px;
  }
  /deep/ .el-carousel__indicator--horizontal .el-carousel__button {
    width: 35px;
    height: 6px;
    border-radius: 20px;
    background: white;
    opacity: 1;
  }
  /deep/ .el-carousel__indicator--horizontal.is-active .el-carousel__button {
    background: #1f3876;
  }
}
.title-box {
  padding-left: 30px;
  .title {
    font-family: "UI-Regular";
    font-size: 52px;
    color: #1f3876;
  }
  .subtitle {
    font-family: "CN-Light";
    font-size: 31px;
    margin-top: 5px;
    color: #90c446;
  }
  .info {
    display: flex;
    margin-top: 29px;
    &-left {
      margin-right: 30px;
      img {
        width: 23px;
        height: 24px;
      }
    }
    &-right {
      flex: 1;
      font-family: "CN-Light";
      font-size: 21px;
      color: #727171;
    }
  }
}
.section1 {
  margin-top: 15px;
  &-box {
    width: 1200px;
    height: 197px;
    background: #ffffff;
    border-radius: 20px 20px 20px 20px;
    box-shadow: 10.4px 9.37px 35px 14px rgba(0, 15, 33, 0.1);
    margin: 0 auto;
    box-sizing: border-box;
    padding-top: 47px;
    position: relative;
    &-text {
      width: 1093px;
      margin: 0 auto;
      font-family: "CN-Light";
      color: #727171;
      font-size: 21px;
      line-height: 36px;
    }
    &-tip {
      width: 1093px;
      margin: 0 auto;
      padding-top: 2px;
      font-family: "CN-Medium";
      font-size: 21px;
      color: #1f3876;
      text-align: right;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      img {
        width: 24px;
        height: 24px;
        vertical-align: middle;
        margin-left: 17px;
      }
    }
    &-tab {
      width: 856px;
      height: 72px;
      background: #ffffff;
      border-radius: 36px 36px 36px 36px;
      box-shadow: 10.4px 9.37px 35px 14px rgba(0, 15, 33, 0.1);
      position: absolute;
      top: -36px;
      left: 0;
      right: 0;
      margin: 0 auto;
      display: flex;
      align-items: center;
      justify-content: space-between;
      font-size: 27px;
      font-family: "CN-Medium";
      color: #1f3876;
      transition: all 0.8s;
      &-item {
        border-radius: 36px;
        display: flex;
        align-items: center;
        height: 100%;
        padding: 0 45px;
        cursor: pointer;
        &-active {
          background: #90c446;
          color: #ffffff;
        }
      }
    }
  }
}
.section2 {
  width: 1200px;
  margin: 0 auto;
  margin-top: 165px;
  .txtCont {
    .title {
      font-size: 32px;
      color: #1f3876;
      line-height: 46px;
      font-weight: bold;
      img {
        width: 24px;
        height: 24px;
      }
      .icon_left {
        margin-right: 15px;
      }
      .icon_right {
        margin-left: 15px;
      }
    }
    .desc {
      margin-top: 5px;
      font-family: "CN-Light";
      color: #727171;
      font-size: 21px;
      line-height: 32px;
      text-indent: 44px;
      text-align: justify;
    }
  }
}
.life {
  .bigImg:hover {
    transform: scale(1.02);
  }
  .nursingStaff,
  .relatives {
    margin-top: 90px;
    display: flex;
    align-items: flex-end;
    .bigImg {
      width: 472px;
      height: 272px;
      margin-right: 86px;
      transition: all 0.8s;
      cursor: pointer;
    }
  }
  .relatives {
    .imgCont {
      position: relative;
      width: 747px;
      height: 430px;
      margin-left: 88px;
      margin-right: 0;
      .bigImg {
        width: 100%;
        height: 100%;
      }
      .iconPlay {
        width: 158px;
        height: 101px;
        position: absolute;
        top: 165px;
        left: 294px;
      }
      .playTitle {
        font-size: 24px;
        line-height: 32px;
        color: #fff;
        width: 100%;
        text-align: right;
        position: absolute;
        bottom: 12px;
        right: 68px;
      }
    }
    .txtCont {
      width: 367px;
      .title {
        text-align: right;
      }
    }
  }
}
.care,
.protect {
  .txtCont {
    display: flex;
    margin: 49px 0 0 30px;
    .icon_right {
      width: 24px;
      height: 24px;
      margin-right: 30px;
    }
    .desc {
      width: 1010px;
      font-size: 22px;
      line-height: 34px;
      color: #727171;
      text-indent: 44px;
      text-align: justify;
    }
  }
  .swiperCont {
    position: relative;
    margin-top: 146px;
    .swiper1 {
      width: 1001px;
      height: 762px;
      .swiper-slide {
        width: 307px;
        height: 762px;
        position: relative;
        border-radius: 20px;
        overflow: hidden;
        .swiperBlock {
          width: 100%;
          height: 20px;
          background: #90c446;
          position: absolute;
          bottom: 0;
          z-index: 1;
        }
        .swiperText1 {
          cursor: pointer;
          position: absolute;
          bottom: -300px;
          width: 100%;
          background: url("https://ecare-health.oss-cn-beijing.aliyuncs.com/www/bringUpCenter/section2-img-bg.png")
            no-repeat;
          background-size: 100% 100%;
          height: 582px;
          padding: 40px 22px;
          box-sizing: border-box;
          transition: all 0.8s;
          &-title {
            font-family: "CN-Medium";
            font-size: 31px;
            color: #1f3876;
            display: flex;
            align-items: center;
            span {
              width: 220px;
            }
            &-img {
              width: 24px;
              height: 23px;
              margin-left: 18px;
              background: url("https://ecare-health.oss-cn-beijing.aliyuncs.com/www/home/arrow-up.png") no-repeat;
              background-size: 100% 100%;
              transition: all 0.8s;
            }
          }
          &-tips {
            font-family: "CN-Light";
            color: #727171;
            font-size: 21px;
            margin-top: 20px;
            text-indent: 42px;
          }
        }
        .swiperText1:hover {
          bottom: 0;
        }
        .swiperText1:hover .swiperText1-title-img {
          background: url("https://ecare-health.oss-cn-beijing.aliyuncs.com/www/home/arrow-down.png") no-repeat;
          background-size: 100% 100%;
        }
      }
    }
    .swiper-button-prev {
      background: url("https://ecare-health.oss-cn-beijing.aliyuncs.com/www/bringUpCenter/icon_prev.png");
      width: 62px;
      height: 62px;
      background-size: auto 100%;
      z-index: 10;
    }
    .swiper-button-next {
      background: url("https://ecare-health.oss-cn-beijing.aliyuncs.com/www/bringUpCenter/icon_next.png");
      width: 62px;
      height: 62px;
      background-size: auto 100%;
      z-index: 10;
    }
    .swiper1-pagination {
      margin-top: 83px;
      text-align: center;
      left: 0;
      right: 0;
      /deep/ .swiper-pagination-bullet {
        width: 29px;
        height: 11px;
        background: #d8d9d9;
        border-radius: 6px;
        margin-right: 27px;
        opacity: 1;
      }
      /deep/ .swiper-pagination-bullet-active {
        width: 63px;
        background: #90c446;
      }
    }
  }
}
.protect {
  .swiperCont2 {
    height: 838px;
    position: relative;
    .swiperBg {
      position: absolute;
      left: 0;
      top: 108px;
      width: 609px;
      height: 609px;
      background: url(https://ecare-health.oss-cn-beijing.aliyuncs.com/www/homeCare/swiper2_bg.png);
      background-size: 100%;
      padding: 66px 0 0 45px;
      box-sizing: border-box;
      .swiperTitle {
        font-family: "UI-Medium";
        font-size: 37px;
        line-height: 45px;
        color: #1f3876;
        .iconRight {
          width: 24px;
          height: 24px;
          margin-left: 40px;
        }
      }
      .swiperLine {
        width: 37px;
        height: 4px;
        background: #1f3876;
        margin: 20px 0;
      }
      .swiperDesc {
        width: 317px;
        font-family: "UI-Light";
        font-size: 21px;
        line-height: 32px;
        color: #727171;
        text-indent: 42px;
        text-align: justify;
      }
    }
    .carouselCont {
      width: 773px;
      height: 493px;
      position: absolute;
      left: 400px;
      top: 166px;
      .swiper-wrapper {
        display: flex;
        align-items: center;
      }
      .swiper-slide {
        cursor: pointer;
        width: 306px;
        height: 415px;
        text-align: center;
        font-size: 18px;
        display: -webkit-box;
        display: -ms-flexbox;
        display: -webkit-flex;
        display: flex;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        -webkit-justify-content: center;
        justify-content: center;
        -webkit-box-align: center;
        -ms-flex-align: center;
        -webkit-align-items: center;
        align-items: center;
      }
      .swiper-slide img {
        width: 100%;
        height: 100%;
      }
      .swiper-slide.swiper-slide-duplicate{
        z-index: 2;
      }
      .swiper-slide.swiper-slide-active {
        width: 362px !important;
        height: 493px;
        z-index: 5;
      }
      .swiper-slide.swiper-slide-next {
        z-index: 4;
      }
      .swiper-slide.swiper-slide-duplicate-prev {
        z-index: 3;
      }
      .swiper-slide.swiper-slide-duplicate-active{
        z-index: 1;
      }
    }
    .carouselPrev,
    .carouselNext {
      width: 62px;
      height: 62px;
      position: absolute;
      left: -93px;
      top: 382px;
      cursor: pointer;
    }
    .carouselNext {
      left: 1200px;
      z-index: 10;
    }
    .progressCont {
      position: absolute;
      top: 800px;
      width: 800px;
      height: 24px;
      line-height: 24px;
      display: flex;
      align-items: center;
      /deep/ .el-progress {
        width: 507px;
        padding-right: 0;
      }
      /deep/ .el-progress-bar {
        padding-right: 0;
      }
      /deep/ .el-progress-bar__outer {
        background: #fff;
      }
      span {
        margin-left: 40px;
        color: #90c446;
        font-family: "UI-Medium";
        font-weight: bold;
        font-size: 24px;
      }
      .small {
        font-size: 16px;
        margin-left: 0;
      }
    }
  }
}
.strategic_bg {
  width: 100%;
  height: 1205px;
  background: url("https://ecare-health.oss-cn-beijing.aliyuncs.com/www/bringUpCenter/bg_img1.png") no-repeat;
  background-size: 100% 100%;
  .strategic {
    padding-top: 270px;
    .title {
      font-weight: bold;
    }
    .subtitle {
      color: #fff;
    }
    .wristbandCont {
      display: flex;
      margin-top: 120px;
      .contLeft,
      .contRight {
        width: 423px;
        margin-right: 80px;
        .itemCont {
          position: relative;
          top: 0;
          height: 85px;
          margin-bottom: 50px;
          .titleBg,
          .titleBg2 {
            cursor: pointer;
            position: absolute;
            top: 0;
            z-index: 2;
            width: 423px;
            height: 85px;
            background: url(https://ecare-health.oss-cn-beijing.aliyuncs.com/www/bringUpCenter/strategic_bg_white1.png);
            background-size: 100% 100%;
            .txt {
              font-size: 30px;
              color: #1f3876;
              line-height: 68px;
              display: inline-block;
              text-align: center;
              width: 375px;
            }
            .icon {
              width: 24px;
              height: 24px;
            }
          }
          .cont {
            position: absolute;
            top: 43px;
            z-index: 0;
            background: #fff;
            border-radius: 0 0 40px 40px;
            width: 423px;
            // height: 220px;
            padding: 40px;
            box-sizing: border-box;
            font-size: 25px;
            line-height: 36px;
            color: #1f3876;
            text-align: justify;
            li {
              list-style-type: none;
            }
          }
          .titleBg2 {
            background: url(https://ecare-health.oss-cn-beijing.aliyuncs.com/www/bringUpCenter/strategic_bg_white2.png);
            background-size: 100% 100%;
          }
        }
        .itemCont.active {
          height: 270px;
          .titleBg {
            background: url(https://ecare-health.oss-cn-beijing.aliyuncs.com/www/bringUpCenter/strategic_bg_green.png);
            background-size: 100% 100%;
            .txt {
              color: #fff;
            }
          }
          .titleBg2 {
            background: url(https://ecare-health.oss-cn-beijing.aliyuncs.com/www/bringUpCenter/strategic_bg_green1.png);
            background-size: 100% 100%;
            .txt {
              color: #fff;
            }
          }
        }
      }
      .wristbandImg {
        width: 208px;
        height: 292px;
        transition: all 0.8s;
        cursor: pointer;
      }
      .wristbandImg:hover {
        transform: scale(1.02);
      }
      .contRight {
        width: 423px;
        margin-left: 68px;
        margin-right: 0;
      }
    }
  }
}
.section6_bg {
  width: 100%;
  height: 1160px;
  background: url("https://ecare-health.oss-cn-beijing.aliyuncs.com/www/bringUpCenter/section6_bg.png") no-repeat;
  background-size: auto 10%;
  background-position: 100% 24%;
}
.section6 {
  width: 1200px;
  margin: 0 auto;
  margin-top: 90px;
  &-header-box {
    margin-left: 30px;
    &-title {
      font-size: 52px;
      font-family: "UI-Regular";
      color: #1f3876;
      font-weight: bold;
    }
    &-tips {
      font-size: 31px;
      font-family: "CN-Light";
      color: #90c446;
      margin-top: 5px;
    }
  }
  &-box {
    width: 1200px;
    height: 535px;
    background: #ffffff;
    border-radius: 25px 25px 22px 25px;
    box-shadow: 10.4px 9.37px 35px 14px rgba(0, 15, 33, 0.1);
    margin-top: 120px;
    // padding: 84px 0;
    padding-bottom: 80px;
    box-sizing: border-box;
    &-row {
      display: flex;
      align-items: center;
      height: 50%;
      flex-wrap: wrap;
      &-item {
        cursor: pointer;
        width: 20%;
        height: 100%;
        color: #1f3876;
        text-align: center;
        display: flex;
        // align-items: center;
        justify-content: center;
        padding-top: 84px;
        box-sizing: border-box;
        &-img {
          width: 100%;
          height: 80px;
          display: flex;
          align-items: center;
          justify-content: center;
        }
        &-text1 {
          font-family: "CN-Medium";
          font-size: 21px;
          margin-top: 25px;
          // margin-bottom: 0px;
        }
        &-text2 {
          font-family: "CN-Medium";
          font-size: 21px;
        }
      }
    }
    .sectionItemBig {
      cursor: pointer;
      display: flex;
      align-items: center;
      padding: 40px 139px 0 98px;
      .itemLeft {
        margin-right: 131px;
        text-align: center;
        font-family: "CN-Blod";
        font-size: 28px;
        margin-top: 30px;
        font-weight: bold;
        color: #1f3876;
        cursor: pointer;
        img {
          width: 159px;
        }
      }
      .itemRight {
        p {
          font-family: "CN-Light";
          font-size: 19px;
          line-height: 32px;
          color: #727171;
          margin-top: 40px;
          position: relative;
          // width: 644px;
        }
        p::before {
          width: 11px;
          height: 11px;
          content: "";
          background: #727171;
          border-radius: 50%;
          position: absolute;
          left: -23px;
          top: 12px;
        }
      }
    }
  }
}
.section7_bg {
  width: 100%;
  height: 1190px;
  background: url("https://ecare-health.oss-cn-beijing.aliyuncs.com/www/bringUpCenter/section7_bg.png") no-repeat;
  background-size: 100% 100%;
}
.section7 {
  width: 1200px;
  margin: 0 auto;
  &-header-box {
    margin-left: 30px;
    padding-top: 150px;
    &-title {
      font-size: 52px;
      font-family: "UI-Regular";
      color: #1f3876;
      font-weight: bold;
    }
    &-tips {
      font-size: 31px;
      font-family: "CN-Light";
      color: #fff;
      margin-top: 5px;
    }
    .horizontal-line {
      margin: 30px 0 118px 0;
      background: #fff;
    }
  }
  &-box {
    width: 1200px;
    height: 657px;
    display: flex;
    align-items: flex-end;
    &-left {
      width: 463px;
      margin-right: 80px;
      &-title {
        font-size: 32px;
        line-height: 47px;
        color: #1f3876;
        font-weight: 500;
        margin-bottom: 5px;
        margin-top: 30px;
      }
      &-text {
        font-size: 21px;
        line-height: 32px;
        color: #727171;
        text-align: justify;
      }
      &-img {
        width: 24px;
        height: 24px;
        margin-left: 10px;
      }
    }
    &-right {
      width: 657px;
      height: 657px;
      background: url(https://ecare-health.oss-cn-beijing.aliyuncs.com/www/bringUpCenter/section7-bg-cricle.png);
      background-size: auto 100%;
      padding-top: 163px;
      box-sizing: border-box;
      &-img {
        width: 627px;
        height: 352px;
        transition: all 0.8s;
        cursor: pointer;
      }
      &-img:hover {
        transform: scale(1.02);
      }
      &-start {
        position: relative;
        top: -205px;
        left: 276px;
        width: 75px;
        height: 49px;
      }
      &-box {
        display: flex;
        &-img {
          width: 64px;
          height: 64px;
          margin-left: 90px;
        }
        &-text {
          font-size: 21px;
          line-height: 32px;
          color: #1f3876;
          text-align: justify;
          width: 382px;
          margin-left: 10px;
          margin-top: 18px;
        }
      }
    }
  }
}
.section8 {
  width: 1200px;
  margin: 0 auto 143px;
  &-header-box {
    margin-left: 30px;
    padding-top: 150px;
    &-title {
      font-size: 52px;
      font-family: "UI-Regular";
      color: #1f3876;
    }
    &-tips {
      font-size: 31px;
      font-family: "CN-Light";
      color: #90c446;
      margin-top: 5px;
    }
    .horizontal-line {
      margin: 30px 0 118px 0;
    }
  }
  &-box {
    width: 1200px;
    &-img {
      width: 1200px;
    }
    &-text {
      display: flex;
      margin-top: 84px;
      img {
        width: 24px;
        height: 24px;
        margin-right: 20px;
      }
      p {
        font-size: 21px;
        line-height: 32px;
        color: #727171;
        text-indent: 42px;
        width: 1051px;
      }
    }
  }
}
</style>
